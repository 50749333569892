body {
	margin: 20px;
	font-family: "Lato", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
	font-weight: 700;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@media (min-width: 719px) {
	body {
		margin: 40px;
	}
}
