.react-tabs {
	-webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
	border-bottom: 1px solid #0075b0;
	margin: 0 0 10px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 10px 20px;
	cursor: pointer;
}

.react-tabs__tab--selected {
	background: #fff;
	border-color: #0075b0;
}

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}
